:root {
  --color-primary-500: rgb(82 102 102 / 50%);
  --color-primary-700: rgb(82 102 102 / 70%);
  --color-primary: rgb(82 102 102 / 100%);

  --color-secondary-500: rgb(145 173 158 / 50%);
  --color-secondary-700: rgb(145 173 158 / 70%);
  --color-secondary: rgb(145 173 158 / 100%);

  --color-tertiary-500: rgb(248 248 107 / 50%);
  --color-tertiary-700: rgb(248 248 107 / 70%);
  --color-tertiary: rgb(248 248 107 / 100%);

  --color-gray: rgb(233 233 240 / 100%);
}

[type="checkbox"]:checked {
  background-image: url("static/svg/checked.svg") !important;
  background-size: 0.7rem !important;
}

/* GENERAL */
[type="checkbox"]:checked {
  border-color: var(--color-secondary) !important;
  background-size: 0.5rem !important;
}
.-top-2px {
  top: -2px;
}
/* PUBLIC */
.w-fit {
  width: fit-content;
}
.public-asl {
  position: absolute;
  line-height: 2rem;
  bottom: 3rem;
  text-transform: uppercase;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translateX(-50%);
}
.public-asl::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 100%;
  border: 2px solid var(--color-primary);
  border-left: 0;
  border-right: 0;
}
.admin-dashboard {
  background-color: var(--color-primary);
}
.admin-dashboard > main {
  min-width: 100vw;
}

@media screen and (min-width: 768px) {
  .admin-dashboard > main {
    min-width: calc(100vw - 288px);
  }
}
.intro-public-asl::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 100%;
  border: 2px solid white;
  border-left: 0;
  border-right: 0;
}
.admin-sidebar {
  min-width: 250px;
}
/* CLIENT STEPS */
.client-container {
  min-height: calc(100vh - 221px);
}
.client-footer > div {
  height: 100px;
  background-image: url("static/img/motifs.png");
}
.intro-container {
  background-image: url("static/img/motifs.png");
  background-repeat: repeat;
}

@media (max-width: 428px) {
  .intro-container {
    background-image: none;
  }
  .text-_8 {
    font-size: 8px !important;
  }
}

.auth-bg {
  background-size: cover;
  background-image: url("static/img/public_bg.jpg");
}

/* DASHBOARD */
.sidebar-menu > span {
  position: relative;
}
.sidebar-menu-selected {
  color: white !important;
  border-bottom-right-radius: 1.25rem;
  background-color: var(--color-primary);
}
.sidebar-menu > span::before {
  content: "";
  position: absolute;
  height: 100%;
  left: 0;
  border-left: 2px solid white;
}
.sidebar-menu-selected > span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8.5px 0 8.5px 12px;
  border-color: transparent transparent transparent #f8f86b;
}
#edd-info::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 2.5px 5px 2.5px 0;
  border-color: transparent var(--color-tertiary) transparent transparent;
  position: absolute;
  left: -5px;
  top: calc(50% - 2.5px);
}
#edd-info {
  display: flex;
  transform: translateY(-25%);
  align-items: center;
  background-color: var(--color-primary);
  padding: 1rem;
}

/* quill  */
.quill .ql-toolbar {
  background-color: white;
  border: 1px solid var(--color-secondary);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.quill .ql-container {
  border: 1px solid var(--color-secondary);
  min-height: 5rem;
  color: white;
}
/* other  */
.border-spacing {
  border-spacing: 0 1rem;
}
.placeholder-white::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

.placeholder-white:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}
.placeholder-secondary::placeholder {
  color: var(--color-secondary);
}

.placeholder-white::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
/* Slick  */
ul.slick-dots {
  bottom: 1rem;
}
ul.slick-dots li {
  margin: 0;
}
ul.slick-dots li button:before {
  content: "";
  top: 25%;
  width: 10px;
  height: 10px;
  border: 1px solid white;
  bottom: 1rem;
  opacity: 1;
  transform: translate(50%);
  background-color: var(--color-primary);
  border-radius: 100%;
}
ul.slick-dots li.slick-active button:before {
  opacity: 1;
  border: 1px solid var(--color-tertiary);
  background-color: var(--color-tertiary);
}
div.slick-arrow {
  display: flex !important;
  /* width: 26px;
  height: 26px; */
  background-color: var(--color-tertiary);
  justify-content: center;
  align-items: center;
}
div.slick-next > svg {
  margin-left: 2.5px;
}
div.slick-prev > svg {
  margin-right: 2.5px;
}
.suffix {
  position: relative;
}
.hideSrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hideSrollBar::-webkit-scrollbar {
  display: none;
}
.suffix::after {
  content: "€";
  position: absolute;
  top: 2px;
  right: 1.5em;
  transition: all 0.05s ease-in-out;
  color: var(--color-secondary);
}
.suffix:hover::after,
.suffix:focus-within::after {
  right: 2.5em;
}
/* handle Firefox (arrows always shown) */
@supports (-moz-appearance: none) {
  .suffix::after {
    right: 2.5em;
  }
}

/* Slick */
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: var(--color-tertiary) !important;
}
div.slick-arrow::before,
div.slick-next::before {
  display: none;
}
.slick-slider,
.slick-list,
.slick-track,
.slick-track div {
  height: 100% !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
